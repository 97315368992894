﻿import { pushToDataLayer } from "../data/data-layer.facade";
import { attachClickEventListener, getBusinessUnit } from "@shared/Utils/analytics-utils";

export type GA4CustomerService = {
  event: "customer_service";
  element_category: string;
  element_cta: string;
  element_url: string;
  business_unit: "B2C" | "B2B";
};

const SECTION_DATA_IDS = ["forside-hoved-navigation", "forside-sub-navigation", "forside-populære-sider"];

export const bootstrapCustomerServiceAnalytics = () => {
  bootstrapCustomerServiceFrontpage();
  bootstrapCustomerServiceGuidanceOverview();
};

const bootstrapCustomerServiceGuidanceOverview = () => {
  const overviewElements = document.querySelectorAll<HTMLElement>("[data-is-support-guidance-overview]");
  for (const overviewElement of overviewElements) {
    const category = overviewElement.dataset.id;

    if (!category) {
      console.error("Missing data-id for data-is-support-guidance-overview");
      continue;
    }

    const elements = overviewElement.querySelectorAll("a");

    attachClickEventListener(elements, (e) => {
      clickCallback(e, category);
    });
  }
};

const bootstrapCustomerServiceFrontpage = () => {
  SECTION_DATA_IDS.forEach((sectionDataId) => {
    const elements = document.querySelectorAll(`[data-id='${sectionDataId}'] a`);

    attachClickEventListener(elements, (e) => {
      clickCallback(e, sectionDataId);
    });
  });
};

const clickCallback = (e: Event, category: string) => {
  const target = e.target as HTMLElement;
  const targetA = target.closest<HTMLAnchorElement>("a");
  const cta = targetA?.dataset.description ?? "";
  const linkUrl = targetA?.href ?? "";

  const event: GA4CustomerService = {
    event: "customer_service",
    element_category: category,
    element_cta: cta,
    element_url: linkUrl,
    business_unit: getBusinessUnit(),
  };

  pushToDataLayer(event);
};
