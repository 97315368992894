import { bootstrapMenuAnalytics } from "@shared/Analytics/content/menu-analytics";
import { bootstrapServicesAppenderContainers } from "./Components/FormServicesAppender";
import { bootstrapContentResizeScale } from "./Components/ContentResizeScale/content-resize-scale";
import { bootstrapEcommerceAnalytics } from "@shared/Analytics/ecommerce/voice/voice-analytics";
import { bootstrapSearchEvents } from "./Components/SearchEvents/search-events";
import { bootstrapFrontPageAnalytics } from "@shared/Analytics/content/frontpage-analytics";
import { handleFirstTabInput } from "@shared/Utils/handle-first-tab";
import { bootstrapFAQAnalytics } from "@shared/Analytics/content/faq-analytics";
import { bootstrapHeaderToolTip } from "@shared/Components/HeaderToolTip/bootstrapHeaderToolTip";
import { bootstrapB2BSubscriptionAnalytics } from "@shared/Analytics/ecommerce/mbb/b2b/mbb-b2b-analytics";
import { bootstrapPhoneClickAnalytics } from "@shared/Analytics/content/phone-analytics";
import { bootstrapSubscriptionAnalytics } from "@shared/Analytics/content/subscription-analytics";
import { bootstrapCookiebotAutofocus } from "./Components/Cookiebot/cookiebot-autofocus";
import { bootstrapCustomerServiceAnalytics } from "@shared/Analytics/content/customer-service-analytics";

// need to be initialized before Dom element is loaded
bootstrapHeaderToolTip();

document.addEventListener("DOMContentLoaded", (): void => {
  bootstrapMenuAnalytics();
  bootstrapFrontPageAnalytics();
  bootstrapCustomerServiceAnalytics();
  bootstrapSubscriptionAnalytics();
  bootstrapB2BSubscriptionAnalytics();
  bootstrapEcommerceAnalytics();
  bootstrapFAQAnalytics();
  bootstrapSearchEvents();
  bootstrapPhoneClickAnalytics();
  bootstrapServicesAppenderContainers();
  bootstrapContentResizeScale();
  bootstrapCookiebotAutofocus();
  handleFirstTabInput();
});
