import { pushToDataLayer } from "../data/data-layer.facade";
import { attachClickEventListener, getBusinessUnit } from "@shared/Utils/analytics-utils";

export type GA4FrontPageEvent = {
  event: "frontpage_tracking";
  element_category: string;
  element_cta: string;
  element_description: string;
  element_url: string;
  business_unit: "B2C" | "B2B";
  index: number;
};

const SECTION_DATA_IDS = ["forside-banner", "hurtig-valg", "Snarveier", "aktuelt", "Mer data"];

export const bootstrapFrontPageAnalytics = () => {
  SECTION_DATA_IDS.forEach((sectionDataId, index) => {
    const elements = document.querySelectorAll(`[data-id='${sectionDataId}'] a`);

    attachClickEventListener(elements, (e) => {
      const target = e.target as HTMLElement;
      const cta = target.closest<HTMLElement>("[data-id]")?.dataset.id ?? "";
      const targetA = target.closest<HTMLAnchorElement>("a");
      const description = targetA?.dataset.description ?? "";
      const linkUrl = targetA?.href ?? "";

      const event: GA4FrontPageEvent = {
        event: "frontpage_tracking",
        element_category: sectionDataId,
        element_cta: cta,
        element_description: description,
        element_url: linkUrl,
        business_unit: getBusinessUnit(),
        index: index,
      };

      pushToDataLayer(event);
    });
  });
};
