import {
  h,
  Fragment,
  type ComponentChild,
  toChildArray,
  type VNode,
  type Attributes,
  type JSX,
  createRef,
} from "preact";
import { useEffect } from "preact/hooks";

export type ToolTipProps = {
  text: string;
  buttonId: string;
  children: ComponentChild;
  isWebcomponent?: boolean;
  delay?: number;
  setbuttonRef?: (element: HTMLElement) => void;
};

interface tooltipAttributes extends Attributes {
  id: string;
}

const getChildArray = (children: ComponentChild, isWebcomponent: boolean) => {
  const childArray = toChildArray(children);

  if (isWebcomponent) {
    const funcArray = (childArray[0] as VNode<tooltipAttributes>).props.children;
    return toChildArray(funcArray);
  }

  return childArray;
};

export const HeaderToolTip = ({ text, buttonId, children, isWebcomponent = false, setbuttonRef }: ToolTipProps) => {
  const buttonref = createRef<HTMLButtonElement>();

  type ButtonProps = JSX.IntrinsicElements["button"] & { className?: string; class?: string };

  const mergeClassNames = (child: VNode<ButtonProps>, appendedClassName: string, isWebComponent: boolean) => {
    const classNames = isWebComponent ? child.props.class : child.props.className;

    return classNames ? `${classNames} ${appendedClassName}` : appendedClassName;
  };

  useEffect(() => {
    if (buttonref?.current && setbuttonRef) {
      setbuttonRef(buttonref.current);
    }
  }, [buttonref.current]);

  return (
    <Fragment>
      {getChildArray(children, isWebcomponent).map((child) => {
        if (
          typeof child === "object" &&
          child.type === "button" &&
          (child as VNode<tooltipAttributes>).props.id === buttonId
        ) {
          return (
            <button
              {...child.props}
              className={mergeClassNames(child, "header-tooltip--trigger", isWebcomponent)}
              key="buttonKey"
              type="button"
              ref={buttonref}
            >
              {child.props.children}
            </button>
          );
        }
        return child;
      })}
      <div className="header-tooltip__text" aria-hidden="true">
        {text}
      </div>
    </Fragment>
  );
};
